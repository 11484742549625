import React, { useState } from "react";
import PSPage from "components/globals/ui/layout/Page";
import Listing from "components/globals/Listing";
import Pane from "components/modals/Pane";
import LoginForm from "components/globals/user/LoginForm";
import { NavLink } from "react-router-dom";
import { Button } from "components/globals/ui/input/Button";
import { useAuth } from "providers/AuthContext";
import { FaInstagram } from "react-icons/fa";

import welcome from "assets/images/welcome.jpg";
import logo from "assets/images/logo.svg";

export default function Welcome() {
  const [loginPane, setLoginPane] = useState(false);
  const { userInfo, isFetching } = useAuth();

  const showLogin = function () {
    setLoginPane(true);
  };

  const hideLogin = function () {
    setLoginPane(false);
  };

  return (
    <>
      <div className="welcome-page">
        <div className="fixed top-6 right-6 z-[3] flex items-center">
          {userInfo ? (
            <NavLink className="button" to="/dashboard/overview">
              <Button variant="outline">Go to dashboard</Button>
            </NavLink>
          ) : (
            <NavLink className="button" onClick={showLogin}>
              <Button variant="outline">Login</Button>
            </NavLink>
          )}
          <a
            href="https://instagram.com/partstash"
            target="blank"
            className="block text-[30px] ml-4"
          >
            <FaInstagram />
          </a>
        </div>

        <div
          className="max-h-[700px] absolute inset-0 z-[1] bg-cover bg-center"
          style={{ backgroundImage: `url(${welcome})` }}
        >
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-background"></div>
        </div>

        <div className="relative z-[2]  w-11/12 mx-auto max-w-screen-md">
          <div className="my-lg text-center">
            <img src={logo} className="w-[150px] mx-auto block" />
            <h1 className="text-[30px] font-bold uppercase text-center mt-xs">
              Partstash
            </h1>
            <p>Where enthusiasts connect.</p>
          </div>

          <div className={userInfo ? "" : "pointer-events-none"}>
            <p>Recent Posts</p>
            <Listing
              apiPath="post"
              apiParams="0/0/0/6"
              displayType="card"
              type="post"
            />
          </div>

          <div className="my-lg text-center">
            {userInfo ? (
              <NavLink className="button" to="/dashboard/overview">
                <Button variant="outline">Go to dashboard</Button>
              </NavLink>
            ) : (
              <NavLink className="button" onClick={showLogin}>
                <Button variant="outline">Login</Button>
              </NavLink>
            )}
          </div>
        </div>
      </div>

      <Pane visible={loginPane} onClose={hideLogin}>
        <LoginForm onClose={hideLogin} />
      </Pane>
    </>
  );
}
