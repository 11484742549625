import { React } from "react";
import Single from "components/globals/image/Single";
import { Link } from "react-router-dom";
import { useFetchData } from "helpers/utils";

const UserBadge = ({ userId, noLink, noPadding, noUserName }) => {
  const {
    data: user,
    loading,
    error,
  } = useFetchData(
    `${process.env.REACT_APP_SERVER_URI}/api/users/publicUserById/${userId}`,
    [userId]
  );

  const containerClasses = `inline-flex items-center rounded-default bg-[#000000]/70 ${
    noPadding ? "" : "p-1 pr-3"
  }`;

  // Loading & error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="relative">
      <div>
        {noLink ? (
          <div className={containerClasses}>
            <div className="relative w-[30px] h-[30px] rounded-small overflow-hidden border-[1px] border-black">
              <Single image={user.gallery?.[0] || null} />
            </div>

            {!noUserName && (
              <span className="font-bold ml-2 text-[14px]">
                {user.username}
              </span>
            )}
          </div>
        ) : (
          <Link
            to={{ pathname: `/users/${user.username}` }}
            className="inline-flex items-center p-1 pr-3 rounded-default bg-[#000000]/70 hover:bg-[#000000]/100 transition"
          >
            <div className="relative w-[30px] h-[30px] rounded-small overflow-hidden border-[1px] border-black">
              <Single image={user.gallery?.[0] || null} />
            </div>
            {!noUserName && (
              <span className="font-bold ml-2 text-[14px]">
                {user.username}
              </span>
            )}
          </Link>
        )}
      </div>
    </div>
  );
};

export default UserBadge;
