import React from "react";
import { Button } from 'components/globals/ui/input/Button';
import Pane from 'components/modals/Pane';
import { NavLink } from 'react-router-dom';

export function SuccessPane({title, onClose, visible}) {
	return (
		<Pane visible={visible} onClose={onClose}>
			<p>{title}</p>
			<div className="flex items-center">
				<Button variant='primary' onClick={onClose}>Create Another</Button>
				<NavLink to={`/dashboard/overview`}>
					<Button variant='primary'>Dashboard</Button>
				</NavLink>
				<NavLink to={`/posts`}>
					<Button variant='primary'>Home</Button>
				</NavLink>
			</div>
		</Pane>
	)
};

export function ErrorPane({onClose, visible, message}) {
	return (
		<Pane visible={visible} onClose={onClose}>
			<p>{message}</p>
		</Pane>
	)
};