import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "assets/images/logo.svg";
import { useAuth } from "providers/AuthContext";
import { Button } from "components/globals/ui/input/Button";
import { FaPlus } from "react-icons/fa6";
import PSCircle from "components/globals/ui/PSCircle";
import Single from "components/globals/image/Single";
import Pane from "components/modals/Pane";
import LoginForm from "components/globals/user/LoginForm";

const Header = () => {
  const { userInfo, isFetching } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const [loginPane, setLoginPane] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (userInfo) {
      setTimeout(function () {
        setProfileImage(userInfo.profile);
      }, 500);
    }
  }, [userInfo]);

  const showLogin = function () {
    setLoginPane(true);
  };

  const hideLogin = function () {
    setLoginPane(false);
  };

  return (
    <>
      <header
        className={`${
          location.pathname === "/" ? "hidden" : ""
        } block fixed w-full z-[2] bg-header text-foreground h-[65px] flex items-center`}
      >
        <div className="flex items-center justify-between w-main">
          <NavLink to="/posts">
            <img src={logo} alt="Partstash" className="block h-[35px] w-auto" />
          </NavLink>

          <div className="flex items-center">
            {!isFetching && (
              <>
                {userInfo ? null : (
                  <NavLink className="button" onClick={showLogin}>
                    <Button variant="outline">Login</Button>
                  </NavLink>
                )}

                <span>
                  {userInfo ? (
                    <div className="flex items-center">
                      <NavLink to="/dashboard/overview">
                        <PSCircle>
                          <Single
                            key={profileImage}
                            image={profileImage}
                            fill
                          />
                        </PSCircle>
                      </NavLink>

                      <NavLink to="/new/post">
                        <PSCircle className="bg-green text-green-foreground">
                          <FaPlus className="text-[25px]" />
                        </PSCircle>
                      </NavLink>
                    </div>
                  ) : null}
                </span>
              </>
            )}
          </div>
        </div>
      </header>

      <Pane visible={loginPane} onClose={hideLogin}>
        <LoginForm onClose={hideLogin} />
      </Pane>
    </>
  );
};

export default Header;
