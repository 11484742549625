import PSPage from "components/globals/ui/layout/Page";

export const EntryLoading = function() {
	return (
		<PSPage>Loading Post details...</PSPage>
	)
}

export const EntryError = function({msg}) {
	return (
		<PSPage>
			<div className="text-center text-[#f23838] m-5">
				<p>{msg}</p>
			</div>
		</PSPage>
	)
}