import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useGetUserEntryQuery } from "services/auth/authService";
import {
  types,
  categories,
  Schema,
  associationTypes,
  associationState,
  idMap,
} from "types/postTypes";
import {
  formatTitle,
  scrollToTop,
  fetchAndMapAssociations,
  clearPostData,
  loadExistingData,
  setCategory,
  createFormData,
} from "helpers/utils";
import PSPage from "components/globals/ui/layout/Page";
import { EntryLoading, EntryError } from "components/globals/EntryStates";
import {
  PostTypes,
  PostCategories,
  PSInputBase,
  PSWysiwyg,
  PSFormControls,
  PSSubmit,
  PSSelect,
  PSLightSwitch,
} from "components/globals/ui/input/PSInputs";
import PSGallery from "components/globals/PSGallery";
import { Button } from "components/globals/ui/input/Button";
import { useDispatch } from "react-redux";
import { createEntry, updateEntry } from "store/authSlice";
import { SuccessPane, ErrorPane } from "components/modals/Panes";

/*
CREATE POST
postData: the data assembled that will be sent to the backend
existingEntryData: the post being edited, set to postData with effect
*/

export default function CreatePost({ edit = false }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [postData, updatePostData] = useState(Schema);
  const userToken = useSelector((state) => state.auth.userToken);
  const dispatch = useDispatch();

  const {
    data: existingEntryData,
    loading: entryLoading,
    error: entryError,
  } = useGetUserEntryQuery(edit ? `post/${id}` : null, { skip: !edit });

  const [associations, setAssociations] = useState(associationState);

  const updateAssociation = (type, value) => {
    setAssociations((prev) => ({ ...prev, [type]: value }));
  };

  // load existing data if editing
  useEffect(() => {
    if (edit && existingEntryData) {
      loadExistingData(updatePostData, Schema, existingEntryData);
    }
  }, [edit, existingEntryData]);

  // reset when navigating here
  useEffect(() => {
    if (!edit) {
      reset();
    }
  }, [edit]);

  // new post, set the first 2
  useEffect(() => {
    if (!edit) {
      setNewPostDataProperty("type", types[0]?.key);
      setCategory(setNewPostDataProperty, categories, types[0]?.key);
    }
  }, []);

  // always default to the first category in the list when
  // updating the post type
  useEffect(() => {
    if (postData.type) {
      setCategory(setNewPostDataProperty, categories, postData.type);
    }
  }, [postData.type]);

  // load all the association data into select boxes
  useEffect(() => {
    const loadAssociations = async () => {
      try {
        const results = await fetchAndMapAssociations(
          userToken,
          associationTypes
        );
        results.forEach(({ type, data }) => {
          updateAssociation(type, data);
        });
        setLoading(false);
      } catch (err) {
        setErrorMessage(err.message);
      }
    };

    loadAssociations();
  }, [userToken]);

  // coming from input onChange (text and select, type, category)
  const onInputChange = (context, val) => {
    setNewPostDataProperty(context, val);
  };

  const updateSelectedFiles = (images) => {
    setNewPostDataProperty("gallery", images);
  };

  const setNewPostDataProperty = (context, val) => {
    updatePostData((prevData) => ({
      ...prevData,
      [context]: val,
    }));
  };

  // SUBMIT
  const submitForm = async () => {
    if (!userToken || (edit && !id)) return;

    if (!postData.title || postData.title.length <= 0) {
      alert("title required");
      return;
    }

    // Set initial state for loading, messages, etc.
    setLoading(true);
    setErrorMessage(null);

    // create the form data for processing
    let fd = createFormData(postData, Schema);

    if (edit) {
      fd.append("internal_id", id);
    }

    try {
      const action = edit
        ? updateEntry({ data: fd, type: "post" })
        : createEntry({ data: fd, type: "post" });
      await dispatch(action).unwrap();
      setSuccess(true);
      reset();
    } catch (err) {
      setErrorMessage(err.message || "Failed. Please try again.");
    } finally {
      setLoading(false);
      scrollToTop();
    }
  };

  const reset = () => {
    clearPostData(updatePostData, Schema);
    setLoading(false);
    setErrorMessage(null);
    setResetRequested(true);
  };

  const handleResetComplete = () => {
    setResetRequested(false);
  };

  const clearErrorMessage = () => {
    setErrorMessage(null);
  };

  const createAnother = () => {
    setSuccess(false);
  };

  if (entryLoading) {
    return <EntryLoading />;
  }
  if (entryError) {
    return <EntryError msg="Failed to load." />;
  }

  return (
    <PSPage
      header={`${edit ? "Edit" : "New"} Post - ${postData?.type || ""} - ${
        postData?.category || ""
      }`}
      key={edit ? id : "new"}
    >
      <SuccessPane
        visible={success}
        onClose={createAnother}
        title={`Successfully ${edit ? "Updated" : "Created"} Post: ${
          postData?.title
        }`}
      />

      <ErrorPane
        visible={errorMessage}
        message={errorMessage}
        onClose={clearErrorMessage}
      />

      <PostTypes
        postType={postData?.type}
        data={types}
        onInputChange={onInputChange}
      />

      <PostCategories
        postType={postData?.type}
        postCategory={postData?.category}
        data={categories}
        onInputChange={onInputChange}
      />

      <PSLightSwitch
        name="private"
        label="Mark Private?"
        value={postData["private"] || false}
        onChange={onInputChange}
      />

      <PSInputBase
        name="title"
        label="Title"
        type="text"
        context="title"
        required
        value={postData.title || ""}
        onChange={onInputChange}
      />

      <PSGallery
        initialImages={postData.gallery || []}
        onImagesChange={updateSelectedFiles}
        resetRequested={resetRequested}
        onResetComplete={handleResetComplete}
      />

      <PSWysiwyg
        name="body"
        label="Body"
        context="body"
        value={postData.body || ""}
        onChange={onInputChange}
      />

      {/* LOOP THROUGH ASSOCIATIONS */}
      {associationTypes.length > 0 && !loading && (
        <>
          {associationTypes.map((association) => {
            const items = associations[association] || [];

            return (
              <div className="my-2" key={association}>
                <PSSelect
                  name={`${idMap[association]}_id`}
                  label={association}
                  firstItemLabel={`Associate with your ${association}`}
                  value={postData[`${idMap[association]}_id`] || ""}
                  onChange={onInputChange}
                >
                  {items.map((item) => (
                    <option value={item.internal_id} key={item.internal_id}>
                      {association === "garage"
                        ? `${formatTitle(item.title)} (${item.year} ${
                            item.make
                          } ${item.model})`
                        : formatTitle(item.title)}
                    </option>
                  ))}
                </PSSelect>
              </div>
            );
          })}
        </>
      )}

      <PSFormControls
        data={postData}
        // debug={true}
      >
        {edit && (
          <Link
            to={`/post/${existingEntryData?.entry?.internal_id}`}
            className="mr-2"
          >
            <Button variant="outline">Cancel</Button>
          </Link>
        )}
        <PSSubmit
          onClick={submitForm}
          loading={loading}
          label={
            loading
              ? edit
                ? "Updating..."
                : "Creating..."
              : edit
              ? "Update Post"
              : "Create Post"
          }
          disabled={loading}
        />
      </PSFormControls>
    </PSPage>
  );
}
