export function getEntryTypePath(entryType) {
  const typeMap = {
    listing: "post",
    garage: "garage/car",
    project: "projects",
    group: "group",
    record: "post",
    event: "events",
    post: "post",
    want: "post",
    update: "post",
    note: "post",
    spot: "post",
    document: "post",
  };

  return typeMap[entryType] || "";
}
