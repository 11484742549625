import React from "react";
import { Link } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';
import { FaExternalLinkAlt } from "react-icons/fa";

export default function PSPage({children, header}) {
  return (
    <>
      <div className="relative pb-[300px]">
        {header && (
          <PSPageHeader title={header} />
        )}

        <div className="w-main  py-2">
          {children}
        </div>
      </div>
    </>
  );
};

export function PSPageHeader({ title, publicProfileLink }) {
  const { userInfo, isFetching } = useAuth();
  
  return (
    <div className="w-full bg-secondary">
      <div className="w-main flex items-center py-2">
        <h1 
          className="
            font-bold 
            tracking-[.5px] 
            text-[17px] 
            uppercase 
            grow
          "
        >{title}</h1>
        
        { !isFetching && publicProfileLink ? (
          <Link className="cursor-pointer flex items-center shrink-0" to={`/users/${userInfo.username}`}>
            <span className="block text-[13px] tracking-[.5px]">Public Profile</span>
            <span className="text-[12px] block ml-1">
              <FaExternalLinkAlt />
            </span>
          </Link>
        ) : null }
      </div>
    </div>
  );
}