import { React, useState, useRef, useEffect } from "react";
import { Button } from 'components/globals/ui/input/Button';
// import { useNavigate } from 'react-router-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useDispatch } from 'react-redux';
import { deleteEntry } from 'store/authSlice';
// import { useDataCache } from 'cache/DataCacheContext';

const DeleteEntryUI = ({ entry }) => {
	const [modalShown, setModalShown] = useState(false);
	const ref = useRef(null);
	const dispatch = useDispatch();
	// const navigate = useNavigate();
	// const { fetchDataWithCache, invalidateCache } = useDataCache();


	function confirmDelete() {
		setModalShown(true);
	}

	function closeModal() {
		setModalShown(false);
	}

	async function fullyDeleteEntry() {
		try {
			const result = await dispatch(deleteEntry(entry)).unwrap();
			if(result.success) {
				window.location.reload();
				// navigate('/dashboard', { replace: true });				
			}
		} catch (error) {
			console.error('Delete failed:', error);
		}
	}
	
	useEffect(() => {
    if (modalShown && ref.current) {
      disableBodyScroll(ref.current);
    } else {
      clearAllBodyScrollLocks();
    }
    return () => clearAllBodyScrollLocks();
  }, [modalShown]);


  return (
		<>
			<Button className="mx-2" onClick={confirmDelete} variant='destructive'>
				Delete
			</Button>

			{/* confirmation modal */}
			{ modalShown && (
				<div className="fixed top-0 left-0 w-full h-full z-[100] flex items-center justify-center" ref={ref}>

					{/* overlay */}
					<div className="absolute inset-0 z-1 backdrop-blur-sm bg-primary opacity-[.9]"></div>

					{/* content */}
					<div className="relative z-2 mx-12 mx-auto bg-[#202020] rounded-default p-sm flex items-center justify-center">
						<Button className="mr-2" onClick={closeModal} variant='outline'>
							<span className="block text-[13px] uppercase font-bold">Cancel</span>  
						</Button>

						<Button className="mr-2" onClick={fullyDeleteEntry} variant='destructive'>
							<span className="block text-[13px] uppercase font-bold">Confirm Delete</span>  
						</Button>
					</div>

				</div>
			)}
			
		</>
  );
};

export default DeleteEntryUI;
