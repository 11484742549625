import { React } from "react";
import { Link } from "react-router-dom";
import Single from "components/globals/image/Single";
import User from "components/globals/user/badge";
import CarBadge from "components/globals/car/badge";
import PSCardImageContainer from "components/globals/ui/card/ImageContainer";
import PSCardInfo from "components/globals/ui/card/Info";
import PSCardTitle from "components/globals/ui/card/Title";
import PSCardDate from "components/globals/ui/card/Date";
import PSCardPrice from "components/globals/ui/card/Price";
import CarDetails from "components/globals/car/Details";
import BottomLeftSlot1 from "components/globals/ui/card/BottomLeftSlot1";
import BottomLeftSlot2 from "components/globals/ui/card/BottomLeftSlot2";
import IsYoursBadge from "components/globals/IsYoursBadge";
import { useSelector, shallowEqual } from "react-redux";
import EntryUserActions from "components/globals/ui/card/EntryUserActions";
import parse from "html-react-parser";

export default function PSCard({
  item,
  price,
  eventInfo,
  carInfo,
  noUserBadge,
  noTypeBadge,
  noUserName,
  noYoursBadge = false,
  path,
  status,
}) {
  const isLoggedIn = useSelector(
    (state) => state.auth.isLoggedIn,
    shallowEqual
  );

  return (
    <>
      <div className="block" key={item.internal_id}>
        <PSCardImageContainer type={item.entry_type}>
          <>
            <Link to={`/${path}/${item.internal_id}`}>
              <Single image={item.gallery[0]} />
            </Link>

            <BottomLeftSlot1>
              {!noUserBadge && (
                <User userId={item.user_id} noUserName={noUserName} />
              )}
            </BottomLeftSlot1>

            {price && <PSCardPrice price={item.price} />}

            {item.car_id && (
              <BottomLeftSlot2>
                <CarBadge car_id={item.car_id} />
              </BottomLeftSlot2>
            )}
          </>
        </PSCardImageContainer>

        <div className="flex items-start relative">
          <Link className="grow" to={`/${path}/${item.internal_id}`}>
            <PSCardInfo>
              {status ? (
                <>
                  {item.status.length > 0 && (
                    <PSCardTitle title={parse(item.status)} />
                  )}
                </>
              ) : (
                <>
                  <PSCardTitle title={item.title} />
                </>
              )}
              {!noYoursBadge && <IsYoursBadge comparison={item.user_id} />}

              <PSCardDate date={item.created_at} />

              {eventInfo && (
                <>
                  {item.event_date ? (
                    <PSCardDate date={item.event_date} label="Event Date" />
                  ) : null}

                  {item.recurring_frequency ? (
                    <span className="block mt-1 text-[13px] text-grey">
                      {item.recurring_frequency}
                    </span>
                  ) : null}
                </>
              )}

              {carInfo && (
                <>
                  <CarDetails
                    year={item.year}
                    make={item.make}
                    model={item.model}
                    trim={item.trim}
                  />
                </>
              )}
            </PSCardInfo>
          </Link>

          {isLoggedIn && (
            <EntryUserActions comparison={item.user_id} item={item} />
          )}
        </div>
      </div>
    </>
  );
}
