import React from "react";
import PSPage, { PSPageHeader } from "components/globals/ui/layout/Page";
import { useDispatch } from "react-redux";
import { logout } from "store/authSlice";
import { Button } from "components/globals/ui/input/Button";
import { NavLink } from "react-router-dom";

import { DashboardSidebar } from "./Sidebar";

export const DashboardTemplate = function ({ title, userInfo, children }) {
  const dispatch = useDispatch();

  const handlelogout = function () {
    dispatch(logout());
  };

  return (
    <PSPage>
      <PSPageHeader title={title} publicProfileLink />
      <div className="md:flex md:items-start w-main">
        <DashboardSidebar userInfo={userInfo} />
        <div className="grow border-[1px] border-[#303030] rounded-lg">
          <div>
            <NavLink to="/new/car">
              <Button variant="primary">New Garage Car</Button>
            </NavLink>
            <NavLink to="/new/project">
              <Button variant="primary">New Project</Button>
            </NavLink>
          </div>
          {children}
        </div>
      </div>
      <p className="my-md border-2 border-red" onClick={handlelogout}>
        LOGOUT
      </p>
    </PSPage>
  );
};
