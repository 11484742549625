import React from "react";

export function PSFormColumns({children}) {
  return (
    <>
      <div className="flex align-start flex-wrap md:flex-nowrap w-main md:2-full mt-5 mb-lg">
        {children}
      </div>
    </>
  );
};


export function PSFormColumn({children, heading}) {
  return (
    <>
      <div className="w-full mb-5 md:w-1/2 md:mr-5 mr-0">

				{ heading ? (
					<h2>{heading}</h2>
				) : null }
				
        {children}
      </div>
    </>
  );
};


export function PSFormControls({children, data, debug = false}) {
  return (
    <div className="
      fixed
      bottom-0
      left-0
      w-full
      px-[40px]
      py-3
      bg-accent">

      {data && debug && (
        <div>
          {Object.entries(data).map(([key, value]) => (
            <p>
              <span className="font-bold text-[11px] text-white">{key}: </span>
              <span className="text-[11px] text-white">{value}</span>
            </p>
          ))}
        </div>
      )}

      <div className="flex justify-end">
        <div className="flex items-center">
          {children}
        </div>    
      </div>
    </div>
  );
};
