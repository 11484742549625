import React from "react";

const Footer = () => {
  return (
    <footer className="bg-footer text-foreground p-[15px] md:p-[30px]">
      <p className="text-[13px]">
        2025 Partstash. <a href="mailto:matt@partstash.com">Contact Support</a>.
      </p>
    </footer>
  );
};

export default Footer;
