import React, { useRef, useState, useEffect, useCallback } from "react";
import { Button } from "components/globals/ui/input/Button";
import { PSLabel } from "components/globals/ui/input/PSInputs";
import { FaX } from "react-icons/fa6";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ITEM_TYPE = "IMAGE";

export default function PSGallery({
  initialImages = [],
  onImagesChange,
  limit = 10,
  resetRequested,
  onResetComplete,
}) {
  const fileInputRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!loaded && initialImages.length > 0) {
      const existingImages = initialImages.map((imgObj) => ({
        file: "",
        filename: imgObj.filename,
        internal_id: imgObj.internal_id,
        index: imgObj.index,
        preview: null,
        removed: false,
        new: false,
      }));

      setImages(existingImages);
      setLoaded(true);
    }
  }, [initialImages]);

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 1000);
  });

  useEffect(() => {
    if (resetRequested) {
      resetGallery();
      if (onResetComplete) {
        onResetComplete();
      }
    }
  }, [resetRequested, onResetComplete]);

  // useEffect(() => {
  //   onImagesChange(images);
  // }, [images, onImagesChange]);

  const handleUpload = (e) => {
    const files = Array.from(e.target.files);
    const nonRemovedImagesCount = images.filter(
      (image) => !image.removed
    ).length;

    if (files.length + nonRemovedImagesCount > limit) {
      alert("Only 10 images allowed.");
      e.target.value = "";
      return;
    }

    // Store actual files along with preview URLs
    const newFiles = files.map((file, index) => ({
      file: file,
      filename: "",
      removed: false,
      preview: URL.createObjectURL(file),
      new: true,
    }));

    setImages((prev) => {
      const updatedImages = [...prev, ...newFiles];
      onImagesChange(updatedImages);
      return updatedImages;
    });

    e.target.value = "";
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const moveImage = useCallback((dragIndex, hoverIndex) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      const [draggedItem] = updatedImages.splice(dragIndex, 1);
      updatedImages.splice(hoverIndex, 0, draggedItem);
      onImagesChange(updatedImages);
      return updatedImages;
    });
  }, []);

  const removeImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];

      if (updatedImages[index].new) {
        URL.revokeObjectURL(updatedImages[index].preview);
        return updatedImages.filter((_, i) => i !== index);
      } else {
        updatedImages[index] = { ...updatedImages[index], removed: true };
        return updatedImages;
      }
    });
  };

  const resetGallery = () => {
    setImages([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      {loaded && (
        <DndProvider backend={HTML5Backend}>
          <PSLabel value={`Gallery: ${images.length}/10`} />
          <Button
            variant="outline"
            onClick={handleClick}
            disabled={images.filter((image) => !image.removed).length >= limit}
          >
            Upload Images
          </Button>
          <input
            ref={fileInputRef}
            id="gallery-upload"
            type="file"
            multiple
            className="sr-only"
            onChange={handleUpload}
            accept="image/*"
          />

          {/* preview area */}
          {images.length > 0 && (
            <div className="p-3 bg-background-dark rounded-[16px] mt-6">
              {images
                .filter((imageObj) => !imageObj.removed)
                .map((imageObj, index) => (
                  <DraggableImage
                    key={index}
                    src={
                      imageObj.preview
                        ? imageObj.preview
                        : `//ps-images.imgix.net/${imageObj?.filename}?q=70&width=800`
                    }
                    index={index}
                    moveImage={moveImage}
                    removeImage={removeImage}
                  />
                ))}
              {images.length > 1 && (
                <span className="italic block mt-3 text-[12px] text-muted tracking-[.05em] pl-3">
                  Drag & Drop to reorder
                </span>
              )}
            </div>
          )}
        </DndProvider>
      )}
    </>
  );
}

const DraggableImage = ({ src, index, moveImage, removeImage }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover: (item) => {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="p-3 relative inline-block opacity-90"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="w-24 h-24 overflow-hidden rounded-[7px] relative cursor-grab">
        <img
          src={src}
          alt="Preview"
          className="absolute w-full h-full inset-0 object-cover"
        />
      </div>

      <button
        onClick={() => removeImage(index)}
        className="
					bg-warning 
					flex 
					w-[20px] 
					h-[20px] 
					absolute 
					top-[5px] 
					right-[5px] 
					cursor-pointer 
					rounded-full
					items-center
					justify-center
					text-white"
      >
        <FaX className="scale-[.6]" />
      </button>

      {index === 0 && (
        <span
          className="
					bg-green 
					text-[11px] 
					font-bold
					px-2 py-0
					text-white 
					rounded-[4px] 
					absolute
					bottom-[2px]
					left-[3px]"
        >
          PRIMARY
        </span>
      )}
    </div>
  );
};
