import React from "react";
import { Button } from "components/globals/ui/input/Button";
import { Link } from "react-router-dom";


export default function PSWidgetHeader({ link, heading }) {
	return (
		<>
			<div className="flex items-center">
				<h3 className="grow">{heading}</h3>
				{ link ? (
					<Link to={link}>
						<Button variant="primary">View All</Button>
					</Link>
					
				) : null }
			</div>

		</>
    
  );
}