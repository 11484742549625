import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "store/authSlice";
import { Button } from "components/globals/ui/input/Button";
import { EntryError } from "components/globals/EntryStates";
// import { PSInputBase } from "components/globals/ui/input/PSInputs"
import PSInputText from "components/globals/ui/input/PSInputText";
import { PSSubmit } from "components/globals/ui/input/PSInputs";

export default function Login({ onClose }) {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const form = useForm();
  const { handleSubmit } = form;
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      if (onClose) {
        onClose();
      }
      navigate("/dashboard/overview");
    }
  }, [navigate, userInfo, onClose]);

  const submitForm = (data) => {
    dispatch(userLogin(data));
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        {error && <EntryError msg={error} />}

        <PSInputText
          register={form.register}
          name="email"
          label="Email Address"
          type="text"
        />
        <PSInputText
          register={form.register}
          name="password"
          label="Password"
          type="password"
        />

        <PSSubmit label="Login" loading={loading} />

        {/* <NavLink to="/register">
					<Button variant="link">Sign up for a new account</Button>
				</NavLink> */}
      </form>
    </>
  );
}
