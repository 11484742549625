import React, { useState, useEffect } from "react";
import {
  PSCollapsableContainer,
  PSCollapsableContainerHeading,
  PSCollapsableContainerContent,
} from "components/globals/ui/PSCollapsableContainer";

import { useGetUserEntriesQuery } from "services/auth/authService";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Button } from "components/globals/ui/input/Button";
import Row from "components/globals/ui/Row";

export default function PSUserListing({
  id,
  heading,
  url,
  newLink,
  allLink,
  type,
  noCollapsableContainer = false,
  noDate = false,
  noTypeBadge = false,
  noUserBadge = false,
  noYoursBadge = false,
}) {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const limit = 12;
  const { data, error, isLoading } = useGetUserEntriesQuery({ page, limit, url });

  useEffect(() => {
    if (data?.entries) {
      setTotal(data?.total);
      setItems((prevItems) => {
        const newItems = data.entries.filter(
          (newItem) => !prevItems.some((item) => item.internal_id === newItem.internal_id)
        );
        return [...prevItems, ...newItems];
      });
    }
  }, [data]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>There was an error loading the garage data.</p>;
  }

  const loadMoreItems = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="border-b-2 border-b-border last:border-b-0" id={id}>
      {noCollapsableContainer ? (
        <>
          
          <div className="flex items-center bg-border p-3">
            <div className="flex items-center grow">
              <h3 className="font-bold">{heading}</h3>
              <span className="font-bold text-[13px] py-1 px-3 bg-primary rounded-full ml-3">
                {total}
              </span>
            </div>

            <div className="shrink-0">
              <Link to={newLink} className="mr-2">
                <Button variant="primary">
                  <FaPlus />
                  <span className="block text-[13px] ml-2 uppercase font-bold">New</span>
                </Button>
              </Link>
            </div>
            
          </div>

          <Items
            items={items}
            data={data}
            type={type}
            limit={limit}
            loadMoreItems={loadMoreItems}
            noDate={noDate}
            noTypeBadge={noTypeBadge}
            noUserBadge={noUserBadge}
            noYoursBadge={noYoursBadge}
          />
        </>
      ) : (
        <PSCollapsableContainer startShown={false}>
          <PSCollapsableContainerHeading>
            <div className="flex items-center">
              <h3>{heading}</h3>
              <span className="font-bold text-[13px] py-1 px-3 bg-primary rounded-full ml-3">
                {total}
              </span>
            </div>
          </PSCollapsableContainerHeading>

          <PSCollapsableContainerContent>
            <Items
              newLink={newLink}
              allLink={allLink}
              items={items}
              data={data}
              type={type}
              limit={limit}
              loadMoreItems={loadMoreItems}
              noDate={noDate}
              noTypeBadge={noTypeBadge}
              noUserBadge={noUserBadge}
              noYoursBadge={noYoursBadge}
            />
          </PSCollapsableContainerContent>
        </PSCollapsableContainer>
      )}
    </div>
  );
}

export function Items({ newLink, allLink, items, data, type, limit, loadMoreItems, noDate, noTypeBadge, noUserBadge, noYoursBadge }) {
  return (
    <>
      {newLink && (
        <Link to={newLink} className="mr-2">
          <Button variant="outline">
            <FaPlus />
            <span className="block text-[13px] ml-2 uppercase font-bold">Add New</span>
          </Button>
        </Link>
      )}

      {allLink && (
        <Link to={allLink}>
          <Button variant="secondary">
            <span className="block text-[13px] uppercase font-bold">View All</span>
          </Button>
        </Link>
      )}

      {items.map((entry, index) => (
        <Row
          type={type}
          key={entry._id}
          item={entry}
          isFirst={index === 0}
          noUserBadge={noUserBadge}
          noYoursBadge={noYoursBadge}
          noDate={noDate}
          noTypeBadge={noTypeBadge}
        />
      ))}

      {data?.entries?.length === limit && (
        <Button variant="outline" className="my-sm" onClick={loadMoreItems}>
          Load More
        </Button>
      )}
    </>
  );
}
