import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { useState, useEffect, useCallback } from "react";
import { useDataCache } from "cache/DataCacheContext";
import { useGetUserEntryQuery } from "services/auth/authService";
import DOMPurify from "dompurify";

export function formatDate(date) {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function formatTitle(title) {
  if (title?.props) {
    return title;
  } else {
    return title.replace("&amp;", "&");
  }
}

export function sanitizeInput(input) {
  return DOMPurify.sanitize(input);
}

export function prepareFormData(data, clearImage) {
  console.log("clear image", clearImage);
  let fd = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    // Log the type of value
    // if (Array.isArray(value)) {
    // 	console.log(`${key} is an array`, value);
    // } else if (typeof value === 'object' && value !== null) {
    // 	console.log(`${key} is an object`, value);
    // } else {
    // 	console.log(`${key} is of type ${typeof value}`, value);
    // }

    if (key === "file") {
      console.log("file val", value);
    }

    if (key === "file" && !clearImage) {
      console.log(
        "key === 'file' && !clearImage",
        key === "file" && !clearImage
      );
      fd.append(key, value[0]);
    } else {
      if (key !== "file") {
        fd.append(key, sanitizeInput(value));
      }
    }
  });

  return fd;
}
export function scrollToTop() {
  window.scrollTo(0, 0);
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// USAGE
// =============================================================
// const { data: user, loading, error } = useFetchData(
// 	`${process.env.REACT_APP_SERVER_URI}/api/users/publicUser/${username}`,
// 	[username],
// );

export const useFetchData = (url, dependencies = [], options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { fetchDataWithCache } = useDataCache();

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchDataWithCache(url);
      setData(response);
    } catch (err) {
      console.error(`Error fetching data from ${url}:`, err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

export const useFetchAuthedData = (url, dependencies = [], options = {}) => {
  const {
    data: queryData,
    error: queryError,
    isLoading: queryLoading,
  } = useGetUserEntryQuery(url);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(queryLoading);

    if (!queryLoading) {
      if (queryError) {
        setError(queryError);
        setData(null);
      } else {
        setError(null);
        setData(queryData);
      }
    }

    // if (!queryLoading && options.scrollToTop) {
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    // }
  }, [queryData, queryError, queryLoading, options.scrollToTop]);

  return { data, loading, error };
};

export const fetchPostAssociation = async (userToken, type) => {
  if (!userToken) {
    throw new Error("No token found");
  }

  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URI}/api/protected/${type}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch post association");
  }

  const data = await response.json();
  return data.entries;
};

export const fetchAndMapAssociations = async (userToken, associationTypes) => {
  try {
    const fetchPromises = associationTypes.map((type) =>
      fetchPostAssociation(userToken, type).then((data) => ({ type, data }))
    );
    return await Promise.all(fetchPromises);
  } catch (err) {
    console.error("Error fetching associations:", err);
    throw err;
  }
};

export const fetchCarDB = async () => {
  
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URI}/api/cars/all`,
  );

  if (!response.ok) {
    throw new Error("Failed to fetch carDB");
  }

  const data = await response.json();
  return data;

};

export function clearPostData(updater, schema) {
  updater(() => {
    return Object.keys(schema).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});
  });
}

export function loadExistingData(updater, schema, data) {
  updater(() => ({
    ...Object.keys(schema).reduce((acc, key) => {
      acc[key] = data?.entry?.[key] ?? "";
      return acc;
    }, {}),
  }));
}

export function setCategory(updater, categories, type) {
  const defaultCategory = categories
    .filter((category) => category.type === type)
    .flatMap((category) => category.items)[0]?.key;
  updater("category", defaultCategory || "");
}

export const createFormData = (postData, schema) => {
  const fd = new FormData();

  Object.keys(schema).forEach((key) => {
    if (postData[key] !== undefined) {
      if (key === "gallery" && Array.isArray(postData[key])) {
        postData[key].forEach((imgObj, index) => {
          console.log("imgObj", imgObj);
          // new images, append the file
          if (imgObj.new) {
            fd.append(key, imgObj.file);
            fd.append(`modifyImage:new:${imgObj.file.name}`, index);
          } else {
            if (imgObj.remove) {
              fd.append(`modifyImage:remove:${index}`, imgObj.filename);
            } else {
              fd.append(
                `modifyImage:setIndex:${index}:${imgObj.filename}`,
                imgObj.index
              );
            }
          }
        });
      } else {
        fd.append(key, sanitizeInput(postData[key]));
      }
    }
  });

  for (const pair of fd.entries()) {
    console.log(pair[0], pair[1]);
  }

  return fd;
};
