import { React, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "components/globals/ui/input/Button";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { FaX } from "react-icons/fa6";

const Pane = ({ visible, children, onClose, actions }) => {
  const modalRef = useRef(null);

  // useEffect(() => {
  //   console.log('in effect', visible, modalRef.current);
  //   if (visible && modalRef?.current) {
  //     disableBodyScroll(modalRef?.current);
  //   } else {
  //     enableBodyScroll(modalRef?.current);
  //   }
  // }, [visible]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="fixed inset-0 z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {/* BG */}
          <motion.div
            className="bg-background-light absolute inset-0 z-[1]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
            onClick={onClose}
          ></motion.div>

          <motion.div
            className="bg-background-dark rounded-bl-[20px] rounded-br-[20px] px-6 pb-12 pt-16 -mt-sm w-full relative z-[2]"
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: -20, opacity: 1 }}
            exit={{ y: "-100%", opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          >
            <div className="relative max-w-lg w-11/12 mx-auto">
              {children}
              <Button
                onClick={onClose}
                className="
                  flex
                  items-center
                  justify-center
                  w-[30px]
                  h-[30px]
                  rounded-full
                  bg-background-light
                  transition
                  absolute
                  -top-xs
                  -right-sm
                "
              >
                <FaX />
              </Button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Pane;
