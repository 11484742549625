import { React } from "react";
import Single from "components/globals/image/Single";
import { Link } from "react-router-dom";
import { useFetchData } from "helpers/utils";

const CarBadge = ({ car_id, noLink }) => {
  // double entry, entry.entry & entry.user
  const {
    data: entry,
    loading,
    error,
  } = useFetchData(
    `${process.env.REACT_APP_SERVER_URI}/api/garage/car/${car_id}`,
    [car_id]
  );

  if (!car_id) {
    return;
  }

  // Loading & error states
  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error: {error.message}</span>;

  return (
    <div className="relative">
      {noLink ? (
        <div className="inline-flex items-center p-1 pr-3 rounded-default bg-[#000000]/70">
          <div className="relative w-[30px] h-[30px] rounded-default overflow-hidden border-[1px] border-black">
            <Single image={entry?.entry?.gallery[0]} />
          </div>
          <span className="font-bold ml-2 text-[14px]">
            {entry.entry.title}
          </span>
        </div>
      ) : (
        <Link
          to={{ pathname: `/car/${entry?.entry?.internal_id}` }}
          className="inline-flex items-center p-1 pr-3 rounded-default bg-[#000000]/70 hover:bg-[#000000]/100 transition"
        >
          <div className="relative w-[30px] h-[30px] rounded-default overflow-hidden border-[1px] border-black">
            <Single image={entry?.entry?.gallery[0]} />
          </div>
          <span className="font-bold ml-2 text-[14px]">
            {entry?.entry?.title}
          </span>
        </Link>
      )}
    </div>
  );
};

export default CarBadge;
