import React, { useState, useEffect } from "react";
import PSPage from "components/globals/ui/layout/Page";
import { EntryLoading, EntryError } from "components/globals/EntryStates"
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {PSInputBase, PSSubmit} from "components/globals/ui/input/PSInputs"
import PSCheckbox from "components/globals/ui/input/PSCheckbox";
import { createEntry, updateEntry } from 'store/authSlice';
import { prepareFormData, scrollToTop } from "helpers/utils";
import { PSFormColumns, PSFormColumn, PSFormControls } from "components/globals/ui/input/PSForms"
import SuccessModal from "components/modals/Success"
import PSWysiwyg from "components/globals/ui/input/PSWysiwyg";
import { useSelector } from 'react-redux'
// import { DevTool } from '@hookform/devtools';
import { useGetUserEntryQuery } from 'services/auth/authService';
import { Button } from 'components/globals/ui/input/Button';
import { Link } from 'react-router-dom';
import SingleImageUploader from "components/globals/ui/image/SingleImageUploader"

export default function CreateCar({edit = false}) {
	const { id } = useParams();
	const dispatch = useDispatch();
	const form = useForm();
	const { handleSubmit, reset, register, control, watch } = form;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const [successItem, setSuccessItem] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const userToken = useSelector((state) => state.auth.userToken);

	// validation
	const title = watch('title');
	const body = watch('body');
	const isFormValid = title && body;

	// what if this is new?
	const { data: entryData, isLoading: entryLoading, error: entryError } = 
		useGetUserEntryQuery(edit ? `events/${id}` : null, { skip: !edit });

	useEffect(() => {
		const initialize = async () => {
			if (edit && entryData) {
					reset({
							...entryData.entry,
					});
			}
		};

		initialize();
	}, [edit, entryData, reset]);


	const submitForm = async (data) => {
		await processEntry(data, edit);
	};
	
	const processEntry = async (data, edit = false) => {
		if (!userToken || (edit && !id)) return;
	
		// Set initial state for loading, messages, etc.
		setLoading(true);
		setError(null);
		setSuccess(false);
		setSuccessMessage('');
	
		const formData = prepareFormData(data);
	
		try {
			const action = edit ? updateEntry({data: formData, type: 'event'}) : createEntry({data: formData, type: 'event'});
			const result = await dispatch(action).unwrap();
	
			const successMessage = edit
				? `Successfully updated Event: ${data.title}`
				: `Successfully created new Event: ${data.title}`;
	
			setSuccessItem(result);
			setSuccess(true);
			setSuccessMessage(successMessage);
			reset();
		} catch (err) {
			setError(err.message || 'Failed. Please try again.');
		} finally {
			setLoading(false);
			scrollToTop();
		}
	};
	

	const closeModal = () => {
		setSuccess(false);
	}

	if (entryLoading) { return <EntryLoading /> }
	if (entryError) { return <EntryError msg="Failed to load." /> }

	return (
		<PSPage>
		
			<div className="w-main">
				<h1>{edit ? 'Edit' : 'New'} Event</h1>
			</div>

			{error && <EntryError msg={error} />}
			{success && 
				<SuccessModal
					visible={success}
					label={successMessage}
					item={successItem}
					onClose={closeModal}
					actions={[
						{
							to: `/event/${successItem?._id}`,
							label: "View",
						},
						{
							to: `/event/${successItem?._id}/edit`,
							label: "Edit",
							onClick: closeModal,
						},
						{
							to: `/new/event`,
							label: "New Event",
							onClick: closeModal,
						},
						{
							to: `/dashboard/overview`,
							label: "Dashboard",
						},
					]}
				/>
			}

			<FormProvider {...form}>
				<form onSubmit={handleSubmit(submitForm)}>
					{/* <DevTool control={control} /> */}
					<PSFormColumns>
						<PSFormColumn heading="Required Fields">
							<PSInputBase
								register={register} 
								name='title' 
								label='Event Name' 
								type='text' 
								required
							/>
							
							{/* Main Image */}
              <SingleImageUploader register={register} reset={reset} />

							<PSWysiwyg elementId='body' label='Description' control={control} name='body' />
							
						</PSFormColumn>

						<PSFormColumn heading="Optional Fields">
							<PSCheckbox name='is_private' label='Mark as Private' control={control} />
            </PSFormColumn>

					</PSFormColumns>

					<PSFormControls>

						<div className="flex items-center">
							{edit && (
								<Link to={`/event/${entryData?.entry?.internal_id}`} className="mr-2">
									<Button variant='outline'>
										<span className="block text-[13px] uppercase font-bold">Cancel</span>  
									</Button>
								</Link>
							)}
							<PSSubmit
								loading={loading}
								label={loading ? (edit ? 'Updating...' : 'Creating...') : (edit ? 'Update Event' : 'Create Event')}
								disabled={loading || !isFormValid}
							/>
						</div>

					</PSFormControls>
				</form>
			</FormProvider>
		</PSPage>
	);
}