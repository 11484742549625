import React from "react";
import { Button } from "components/globals/ui/input/Button";
import { Link } from "react-router-dom";

export default function PSPageSegmentHeading({heading, all}) {
  return (
    <>
      <div className="flex items-center mb-3 p-[15px] md:p-0">
        { heading ? (
          <h3 className="style-h3 grow">{heading}</h3>
        ): null }

        { all ? (
          <Link to={all}>
            <Button variant="primary">View All</Button>
          </Link>
        ) : null }
      </div>
      
    </>
  );
};
