export const Schema = {
  private: "",
  gallery: "",
  title: "",
  body: "",
  type: "",
  category: "",
  car_id: "",
};

export const associationTypes = ["garage"];

export const associationState = {
  garage: "",
};

export const idMap = {
  garage: "car",
};

export const types = [
  {
    key: "general",
    label: "General",
  },
  {
    key: "restoration",
    label: "Restoration",
  },
  {
    key: "maintenance",
    label: "Maintenance",
  },
  {
    key: "mod",
    label: "Mod",
  },
  {
    key: "other",
    label: "Other",
  },
];

export const categories = [
  {
    type: "general",
    items: [
      {
        key: "cat1",
        label: "Category 1",
      },
      {
        key: "cat2",
        label: "Category 2",
      },
    ],
  },

  {
    type: "restoration",
    items: [
      {
        key: "cat1",
        label: "Category 1",
      },
      {
        key: "cat2",
        label: "Category 2",
      },
    ],
  },

  {
    type: "maintenance",
    items: [
      {
        key: "cat1",
        label: "Category 1",
      },
      {
        key: "cat2",
        label: "Category 2",
      },
    ],
  },

  {
    type: "mod",
    items: [
      {
        key: "cat1",
        label: "Category 1",
      },
      {
        key: "cat2",
        label: "Category 2",
      },
    ],
  },

  {
    type: "other",
    items: [
      {
        key: "cat1",
        label: "Category 1",
      },
      {
        key: "cat2",
        label: "Category 2",
      },
    ],
  },
];
