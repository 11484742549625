import { React } from "react";

export default function TypeBadge({type}) {

	const getBgColor = (type) => {
		if(!type) { return }
    switch (type.toLowerCase()) {
      case "listing":
        return "bg-badge-listing text-badge-listing-foreground";
      case "want":
        return "bg-badge-want text-badge-want-foreground";
      case "garage":
        return "bg-badge-garage text-badge-garage-foreground";
      case "event":
        return "bg-badge-event text-badge-event-foreground";
			case "group":
				return "bg-badge-group text-badge-group-foreground";
			case "record":
				return "bg-badge-record text-badge-record-foreground";
			case "timeline":
				return "bg-badge-status text-badge-status-foreground";
      default:
        return "bg-badge-default text-badge-default-foreground";
    }
  };

	const getTypeString = (type) => {
		if(!type) { return }
    switch (type.toLowerCase()) {
      case "listing":
        return "Listing";
      case "want":
        return "Want-Ad";
      case "garage":
        return "User Car";
      case "event":
        return "Event";
			case "group":
				return "Group";
			case "record":
				return "Car Record";
			case "timeline":
				return "Timeline";
      default:
        return "TEST";
    }
  };

	return (
		<>
			{ type ? (
				<div 
					className={`
						py-[3px]
						px-[7px] 
						border-[1px]
						border-black/50
						uppercase
						font-bold
						text-[11px]
						rounded-small
						${getBgColor(type)}`}
					>
					{ getTypeString(type) }
				</div>
			) : null }
			
		</>
  );
}