import React from 'react';
import { NavLink } from 'react-router-dom';

export const DashboardSidebar = function() {
  return (

    <div className="
      md:w-[200px]
      md:mr-12 
      rounded-small 
      shrink-0 
      flex
      justify-between
      md:justify-start
      md:flex-col
      md:sticky
      md:flex-wrap
      my-3
      md:my-0
      top-[70px] 
      bg-secondary 
      border-2 
      border-secondary
      font-bold
      uppercase
      text-[13px]
      overflow-hidden">

      <NavLink 
        to="/dashboard/overview"
        className={({ isActive }) =>
          isActive
            ? "w-1/3 shrink-0 flex items-center justify-center block text-accent-foreground tive bg-accent px-1 py-3"
            : "w-1/3 shrink-0 flex items-center justify-center block text-foreground px-1 py-3"
        }>
        My Content
      </NavLink>
      <NavLink 
        to="/dashboard/garage"
        className={({ isActive }) =>
          isActive
            ? "w-1/3 shrink-0 flex items-center justify-center block text-accent-foreground bg-accent px-1 py-3"
            : "w-1/3 shrink-0 flex items-center justify-center block text-foreground px-1 py-3"
        }>
        Garage
      </NavLink>
      <NavLink 
        to="/dashboard/settings"
        className={({ isActive }) =>
          isActive
            ? "w-1/3 shrink-0 flex items-center justify-center block text-accent-foreground bg-accent px-1 py-3"
            : "w-1/3 shrink-0 flex items-center justify-center block text-foreground px-1 py-3"
        }>
        Settings
      </NavLink>

    </div>
    

  );
}