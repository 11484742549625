import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PSPage from 'components/globals/ui/layout/Page';
import LoginForm from "components/globals/user/LoginForm"

export default function Login() {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      navigate('/dashboard/overview');
    }
  }, [navigate, userInfo]);

  return (
    <>
      <PSPage>
        <div className="max-w-[600px] w-main mt-lg">
          <h1>Login</h1>
          <LoginForm />
        </div>
      </PSPage>
    </>
  );
}
