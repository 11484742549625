import * as React from "react"
import clsx from "clsx";

const PSCircle = ({className, children}) => {  
  return (
		<div 
			className={clsx(
				"cursor-pointer w-[40px] h-[40px] rounded-full mx-1 relative overflow-hidden",
				className
			)}
		>
			<div className="absolute inset-0 flex items-center justify-center">
				{children}
			</div>
			
		</div>

  );
};

export default PSCircle;
