import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import { useAuth } from "providers/AuthContext";
import { DashboardTemplate } from "./Dashboard";
import PSUserListing from "components/globals/ui/dashboard/PSUserListing";

export default function Dashboard() {
  const { userInfo, isFetching } = useAuth();

  return (
    <PSPage>
      {!isFetching && userInfo ? (
        <DashboardTemplate userInfo={userInfo} title="My Content">
          <PSUserListing
            id="cars"
            heading="Your Cars"
            url="api/protected/garage"
            type="cars"
            newLink="/new/car"
            allLink="/dashboard/garage"
          />

          <PSUserListing
            id="groups"
            heading="Your Groups"
            url="api/protected/groups"
            type="groups"
            newLink="/new/group"
            allLink="/dashboard/groups"
          />

          <PSUserListing
            id="listings"
            heading="Your Listings"
            url="api/protected/listings"
            type="listings"
            newLink="/new/listing"
            allLink="/dashboard/listings"
          />

          <PSUserListing
            id="wants"
            heading="Your Want-Ads"
            url="api/protected/wants"
            type="wants"
            newLink="/new/want"
            allLink="/dashboard/wants"
          />

          <PSUserListing
            id="events"
            heading="Your Events"
            url="api/protected/events"
            type="events"
            newLink="/new/event"
            allLink="/dashboard/events"
          />

          <PSUserListing
            id="records"
            heading="Your Records"
            url="api/protected/records"
            type="records"
            newLink="/new/record"
            allLink="/dashboard/records"
          />

          <PSUserListing
            id="timeline"
            heading="Your Statuses"
            url="api/protected/timeline"
            type="timeline"
            newLink="/new/timeline"
            allLink="/dashboard/timeline"
          />

          <PSUserListing
            id="projects"
            heading="Your Projects"
            url="api/protected/projects"
            type="projects"
            newLink="/new/project"
            allLink="/dashboard/projects"
          />
        </DashboardTemplate>
      ) : null}
    </PSPage>
  );
}
