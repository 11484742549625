import React from 'react';
import PSPage from 'components/globals/ui/layout/Page';
import { useAuth } from 'providers/AuthContext';
import { DashboardTemplate } from './Dashboard';
import PSUserListing from "components/globals/ui/dashboard/PSUserListing"

export default function Dashboard() {

	const { userInfo, isFetching } = useAuth();

	return (
		<PSPage>
			{ !isFetching && userInfo ? (
				<DashboardTemplate userInfo={userInfo} title="My Garage">

					<PSUserListing 
						id="cars" 
						heading="Your Cars" 
						url="api/protected/garage" 
						type="cars"
						newLink="/new/car"
						noCollapsableContainer
						noDate
						noTypeBadge
						noUserBadge
						noYoursBadge />
					
				</DashboardTemplate>
			) : null }
		</PSPage>
	);
}