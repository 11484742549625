export const Schema = {
  private: "",
  gallery: "",
  title: "",
  body: "",
  type: "",
  category: "",
  car_id: "",
  group_id: "",
  project_id: "",
  event_id: "",
};

export const associationTypes = ["garage", "projects", "events", "groups"];

export const associationState = {
  garage: "",
  projects: "",
  events: "",
  groups: "",
};

export const idMap = {
  garage: "car",
  groups: "group",
  events: "event",
  projects: "project",
};

export const types = [
  {
    key: "update",
    label: "Update",
  },
  {
    key: "listing",
    label: "Listing (for sale)",
  },
  {
    key: "want",
    label: "Want-ad",
  },
  {
    key: "note",
    label: "Note",
  },
  {
    key: "spot",
    label: "Spotted",
  },
  {
    key: "record",
    label: "Car Record",
  },
  {
    key: "document",
    label: "Document",
  },
];

export const categories = [
  {
    type: "update",
    items: [
      {
        key: "detail",
        label: "Detailing",
      },
      {
        key: "drive",
        label: "Driving",
      },
      {
        key: "general",
        label: "General",
      },
    ],
  },
  {
    type: "listing",
    items: [
      {
        key: "listing1",
        label: "Listing Category 1",
      },
      {
        key: "listing2",
        label: "Listing Category 2",
      },
    ],
  },
  {
    type: "want",
    items: [
      {
        key: "want1",
        label: "Want Category 1",
      },
      {
        key: "want2",
        label: "Want Category 2",
      },
    ],
  },

  {
    type: "note",
    items: [
      {
        key: "note1",
        label: "Note Category 1",
      },
      {
        key: "note2",
        label: "Note Category 2",
      },
    ],
  },

  {
    type: "spot",
    items: [
      {
        key: "c&c",
        label: "Cars & Coffee",
      },
      {
        key: "museum",
        label: "Museum",
      },
      {
        key: "shop",
        label: "Shop",
      },
      {
        key: "other",
        label: "Other",
      },
    ],
  },

  {
    type: "record",
    items: [
      {
        key: "general",
        label: "General",
      },
      {
        key: "mod",
        label: "Mod",
      },
      {
        key: "restoration",
        label: "Restoration",
      },
      {
        key: "maintenance",
        label: "Maintenance",
      },
      {
        key: "other",
        label: "Other",
      },
    ],
  },

  {
    type: "document",
    items: [
      {
        key: "document1",
        label: "Document Category 1",
      },
      {
        key: "document2",
        label: "Document Category 2",
      },
    ],
  },
];
